<template
  >
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-else>
      <van-nav-bar :title="title" left-arrow @click-left="onClickLeft">
      </van-nav-bar>
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        style="height: 150px; backgroundcolor: #ccc"
      >
        <van-swipe-item
          v-for="item in images"
          :key="item.id"
          style="height: 150px"
        >
          <img :src="item.imgUrl" alt="" style="width: 100%; height: 100%" />
        </van-swipe-item>
      </van-swipe>
      <van-tabs v-model="tabactive">
        <van-tab title="介绍">
          <!-- 列表 -->
          <div style="margin-top: 10px; margin-left: 20px; margin-right: 20px">
            <van-row>
              <van-col span="6"><strong>品牌：</strong></van-col>
              <van-col span="18">{{ brand }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6"><strong>地址：</strong></van-col>
              <van-col span="18">{{ address }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6"><strong>系列：</strong></van-col>
              <van-col span="18">{{ series }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6"><strong>人数限定：</strong></van-col>
              <van-col span="18">{{ peopleNumber }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6"><strong>场地平米：</strong></van-col>
              <van-col span="18">{{ venueSize }}</van-col>
            </van-row>
            <van-row>
              <van-col span="6"><strong>基础标准：</strong></van-col>
              <van-col span="18">{{ infrastructureStandards }}</van-col>
            </van-row>
            <van-row>
              <van-col span="5"><strong>场地费：</strong></van-col>
              <van-col span="19">
                <van-row>
                  <van-col span="12"
                    ><strong style="font-size: smaller; margin-left: 3px"
                      >普通价格</strong
                    >
                    <strong
                      style="color: green; margin-left: 10px"
                      v-if="departmentvip == false"
                      >✓</strong
                    >
                    <p
                      v-for="item in venue"
                      :key="item.id"
                      style="margin-top: 3px; font-size: smaller"
                    >
                      ￥{{ item.fee }}元 / {{ item.hours }}小时
                    </p>
                    <p
                      style="margin-top: 3px; font-size: smaller"
                      v-if="venueDescribe"
                    >
                      ({{ item.venueDescribe }})
                    </p>
                  </van-col>
                  <van-col span="12"
                    ><strong style="font-size: smaller; margin-left: 3px"
                      >VIP价格</strong
                    >
                    <strong
                      style="color: green; margin-left: 10px"
                      v-if="departmentvip == true"
                      >✓</strong
                    >
                    <p
                      v-for="item in vipvenue"
                      :key="item.id"
                      style="margin-top: 3px; font-size: smaller"
                    >
                      ￥{{ item.fee }}元 / {{ item.hours }}小时
                    </p>
                    <p
                      style="margin-top: 3px; font-size: smaller"
                      v-if="venueVIPDescribe"
                    >
                      ({{ venueVIPDescribe }})
                    </p>
                  </van-col>
                </van-row>
              </van-col>
            </van-row>
            <van-row>
              <van-col span="5"><strong>已预约：</strong></van-col>
              <van-col span="19" style="font-size: smaller">
                <p
                  style="margin-top: 3px"
                  v-for="item in reserveddate"
                  :key="item.id"
                >
                  {{ dateWithWeekStr(item.startTime) }} -
                  {{ dateWithWeekStr(item.endTime) }}
                  <van-tag
                    type="success"
                    v-if="item.creatorId == $store.state.user.id"
                    round
                    >我的</van-tag
                  >
                  <van-tag
                    type="success"
                    v-else
                    round
                    @click.stop="toUserHomePage(item.creatorId)"
                    >{{ item.creator }}</van-tag
                  >
                </p></van-col
              >
            </van-row>
          </div>
        </van-tab>
        <van-tab title="去预约">
          <div style="margin-top: 10px; margin-left: 20px; margin-right: 20px">
            <div style="margin-top: 10px" @click="show1 = true">
              <strong>选择预约时间</strong>
              <van-cell is-link
                >开始时间：{{ this.starttime }} <br />
                结束时间：{{ this.endtime }}
              </van-cell>
            </div>

            <van-action-sheet v-model="show1">
              <strong style="margin-left: 15px" v-if="reserveddate !== []">
                已预约时间：
              </strong>
              <div
                style="
                  margin-left: 15px;
                  padding-top: 5px;
                  max-height: 80px;
                  overflow-y: auto;
                  font-size: small;
                "
              >
                <p
                  style="margin-top: 3px"
                  v-for="item in reserveddate"
                  :key="item.id"
                >
                  {{ dateWithWeekStr(item.startTime) }} -
                  {{ dateWithWeekStr(item.endTime) }}
                  <van-tag
                    type="success"
                    v-if="item.creatorId == $store.state.user.id"
                    round
                    >我的</van-tag
                  >
                </p>
              </div>
              <van-datetime-picker
                v-model="currentTime1"
                type="datetime"
                title="开始时间"
                :min-date="minDateStart"
                :max-date="maxDateStart"
                :formatter="formatter"
                @confirm="confirm1"
                @cancel="cancel1"
              />
            </van-action-sheet>
            <van-action-sheet v-model="show2">
              <strong style="margin-left: 15px" v-if="reserveddate !== []">
                已预约时间：
              </strong>
              <div
                style="
                  margin-left: 15px;
                  padding-top: 5px;
                  max-height: 80px;
                  overflow-y: auto;
                  font-size: small;
                "
              >
                <p
                  style="margin-top: 3px"
                  v-for="item in reserveddate"
                  :key="item.id"
                >
                  {{ dateWithWeekStr(item.startTime) }} -
                  {{ dateWithWeekStr(item.endTime) }}
                  <van-tag
                    type="success"
                    v-if="item.creatorId == $store.state.user.id"
                    round
                    >我的</van-tag
                  >
                </p>
              </div>
              <van-datetime-picker
                v-model="currentTime2"
                type="datetime"
                title="结束时间"
                :min-date="minDateEnd"
                :max-date="maxDateEnd"
                :formatter="formatter"
                @confirm="confirm2"
                @cancel="cancel2"
              />
            </van-action-sheet>

            <strong>可选套餐</strong>
            <div style="margin-top: 5px">
              <van-row
                v-for="item in combo"
                :key="item.id"
                style="height: 30px"
              >
                <van-col span="2">
                  <van-checkbox v-model="item.checked" icon-size="18px">
                  </van-checkbox>
                </van-col>
                <van-col
                  span="16"
                  @click="
                    dialog(
                      item.title,
                      item.description,
                      item.chargeType,
                      item.reducedFee,
                      item.vipReducedFee
                    )
                  "
                >
                  <span style="font-size: smaller"
                    >{{ item.title }} ￥{{ item.fee }}/{{
                      item.chargeType == 0
                        ? "次"
                        : item.chargeType == 1
                        ? "人"
                        : item.chargeType == 2
                        ? "套"
                        : ""
                    }}
                    (点击查看)
                  </span>
                </van-col>
                <van-col span="6"
                  ><van-stepper
                    v-model="item.num"
                    theme="round"
                    button-size="18"
                    :disabled="item.chargeType == 0"
                /></van-col>
              </van-row>
            </div>
            <strong style="margin-top: 5px">可选单项</strong>
            <div style="margin-top: 5px">
              <van-row v-for="item in good" :key="item.id" style="height: 30px">
                <van-col span="2">
                  <van-checkbox v-model="item.checked" icon-size="18px">
                  </van-checkbox>
                </van-col>
                <van-col span="16" style="font-size: smaller">
                  {{ item.title }} ￥{{ item.fee }}/{{
                    item.chargeType == 0
                      ? "次"
                      : item.chargeType == 1
                      ? "人"
                      : item.chargeType == 2
                      ? "套"
                      : ""
                  }}
                </van-col>
                <van-col span="6">
                  <van-stepper
                    v-model="item.num"
                    theme="round"
                    button-size="18"
                /></van-col>
              </van-row>
            </div>
          </div>

          <van-form validate-first>
            <van-field
              label="会议主题"
              colon:true
              v-model="zhuti"
              name="123"
              error-message="必填项"
              required
              placeholder="请输入会议主题"
              :rules="[{ required: true, message: '请输入正确内容' }]"
            />
            <van-field
              v-model="message"
              rows="2"
              autosize
              label="留言"
              type="textarea"
              maxlength="50"
              placeholder="您有其他需求可以在此留言"
              show-word-limit
            />
            <div style="margin: 16px">
              <van-button
                round
                block
                type="info"
                @click="submit"
                :loading="isSubmiting"
              >
                提交
              </van-button>
            </div>
          </van-form>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import dayjs from "dayjs";

import {
  getMeetingDetailAPI,
  getMeetingRoomImgAPI,
  getMeetingRoomComboAPI,
  getMeetingRoomVenueAPI,
  getMeetingRoomReservedCreateAPI,
  getMeetingRoomReservedDateListAPI,
  getIsMeetingRoomVipAPI,
} from "../../api/reserveMeeting";

export default {
  data() {
    return {
      isSubmiting: false,
      activeKey: 0,
      message: "",
      zhuti: "",
      tabactive: "",
      currentTime1: new Date(),
      currentTime2: new Date(),
      show1: false,
      show2: false,
      starttime: dayjs(new Date()).format("MM月DD日HH时mm分"),
      endtime: dayjs(new Date()).format("MM月DD日HH时mm分"),
      activeNames: [0],
      loading: true,
      title: "会议室",
      obj: {
        current: 1,
        pageSize: 100,
        filterData: {
          status: 1,
          meetingRoomId: this.$route.query.id,
        },
      },
      images: [],
      // 会议室地址
      address: "",
      // 品牌
      brand: "",
      // 系列
      series: "",
      // 会议室基础标准
      infrastructureStandards: "",
      // 人数限定
      peopleNumber: "",
      // 场地平米
      venueSize: "",
      //  套餐
      combo: [],
      good: [],
      // 场地费
      venue: [],
      // vip场地费
      vipvenue: [],
      // 已经预约的时间
      reserveddate: [],
      // 判断是否vip
      departmentvip: "",
      venueDescribe: "",
      venueVIPDescribe: "",
    };
  },
  computed: {
    minDateStart() {
      return new Date(new Date().getTime() + 5 * 60000);
    },
    maxDateStart() {
      return new Date(this.minDateStart.getTime() + 90 * 24 * 60 * 60000);
    },
    minDateEnd() {
      return new Date(
        (this.currentTime1 > this.minDateStart
          ? this.currentTime1
          : this.minDateStart
        ).getTime() +
          30 * 60000
      );
    },
    maxDateEnd() {
      return new Date(this.maxDateStart.getTime() + 5 * 60000);
    },
  },
  created() {
    this.starttime = dayjs(this.minDateStart).format("MM月DD日HH时mm分");
    this.endtime = dayjs(this.minDateEnd).format("MM月DD日HH时mm分");

    this.initMeetingImg();
    this.initMeetingDetail();
    this.initgetMeetingRoomCombo();
    this.inieMeetingRoomVenue();
    this.initMeetingRoomReservedDateList();
    this.initIsMeetingRoomVip();
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    async inieMeetingRoomVenue() {
      const result = await getMeetingRoomVenueAPI(this.obj);
      let { data } = result.data;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        if (data[i].itemType == 1) {
          this.vipvenue.push(data[i]);
        } else {
          this.venue.push(data[i]);
        }
      }
    },
    async initgetMeetingRoomCombo() {
      const result = await getMeetingRoomComboAPI(this.obj);
      let { data } = result.data;
      for (let i = 0; i < data.length; i++) {
        data[i].checked = false;
        data[i].num = 1;
        if (data[i].itemType === 1) {
          this.good.push(data[i]);
        } else {
          this.combo.push(data[i]);
        }
      }
    },
    async initMeetingDetail() {
      const result = await getMeetingDetailAPI(this.$route.query.id);
      let { data } = result.data;
      this.address = data.address;
      this.brand = data.brand;
      this.series = data.series;
      this.infrastructureStandards = data.infrastructureStandards;
      this.peopleNumber = data.peopleNumber;
      this.venueSize = data.venueSize;
      this.loading = false;
    },
    async initMeetingImg() {
      const result = await getMeetingRoomImgAPI({
        meetingRoomId: this.$route.query.id,
      });
      let { data } = result.data;
      this.images = data;
    },
    // 已经预约时间
    async initMeetingRoomReservedDateList() {
      const result = await getMeetingRoomReservedDateListAPI({
        meetingRoomOriginalId: this.$route.query.id,
        status: 0,
      });
      let { data } = result.data;
      console.log(data);
      this.reserveddate = data;
    },
    // 判断是不是vip部门
    async initIsMeetingRoomVip() {
      const result = await getIsMeetingRoomVipAPI();
      this.departmentvip = result.data.data;
    },
    // 时间处理
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      return val;
    },
    confirm1() {
      this.starttime = this.dateWithWeekStr(
        this.currentTime1,
        "MM月DD日HH时mm分"
      );
      this.show1 = false;
      this.show2 = true;
      console.log("开始时间", this.currentTime1);
    },
    confirm2() {
      this.endtime = this.dateWithWeekStr(
        this.currentTime2,
        "MM月DD日HH时mm分"
      );
      this.show2 = false;
      console.log("结束时间", this.currentTime2);
    },
    cancel1() {
      this.show1 = false;
    },
    cancel2() {
      this.show2 = false;
    },
    dialog(title, description, chargeType, reducedFee, vipReducedFee) {
      Dialog.alert({
        title: title,
        message: `套餐描述：${description} ${
          this.departmentvip == false && chargeType == 2 && reducedFee
            ? "<br/>选择此套餐可减免费用：" + reducedFee
            : ""
        } ${
          this.departmentvip == true && chargeType == 2 && vipReducedFee
            ? "<br/>选择此套餐可减免费用：" + vipReducedFee
            : ""
        }`,
      }).then(() => {});
    },

    dateWithWeekStr(date, baseFormat = "MM-DD HH:mm") {
      let str = this.dayjs(date).format(baseFormat);
      switch (this.dayjs(date).day()) {
        case 0:
          str += "(周日)";
          break;
        case 1:
          str += "(周一)";
          break;
        case 2:
          str += "(周二)";
          break;
        case 3:
          str += "(周三)";
          break;
        case 4:
          str += "(周四)";
          break;
        case 5:
          str += "(周五)";
          break;
        case 6:
          str += "(周六)";
          break;
      }
      return str;
    },

    submit() {
      this.isSubmiting = true;
      Dialog.confirm({
        message: "您确定选好了么？",
      })
        .then(async () => {
          this.currentTime2.setMinutes(
            this.currentTime2.getMinutes() -
              this.currentTime2.getTimezoneOffset()
          );
          this.currentTime1.setMinutes(
            this.currentTime1.getMinutes() -
              this.currentTime1.getTimezoneOffset()
          );
          let resrved = [];
          for (let i = 0; i < this.combo.length; i++) {
            if (this.combo[i].checked == true) {
              resrved.push(this.combo[i]);
            }
          }
          for (let i = 0; i < this.good.length; i++) {
            if (this.good[i].checked == true) {
              resrved.push(this.good[i]);
            }
          }
          console.log(resrved);
          const result = await getMeetingRoomReservedCreateAPI({
            endTime: this.currentTime2,
            startTime: this.currentTime1,
            meetingRoomId: this.$route.query.id,
            comboReservedList: resrved,
            title: this.zhuti,
            creatorMemo: this.message,
          });
          this.isSubmiting = false;
          console.log(result);
          let data = result.data;
          if (data.success == true) {
            Dialog.alert({
              title: "成功",
              message: "预约成功",
            }).then(() => {
              this.$router.push("/reservemeeting");
            });
          }
          if (data.success == false) {
            Dialog.alert({
              title: "失败",
              message: data.errorMessage,
            }).then(() => {});
          }
        })
        .catch(() => {
          this.isSubmiting = false;
        });
    },
    toUserHomePage(id) {
      this.$router.push({
        path: "/userHomepage",
        query: { para: id },
      });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 100px;
}
.loading {
  text-align: center;
}
</style>
